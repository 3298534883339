import React from 'react';

export default ({ style }) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 512" style={{ width: '3em', ...style, enableBackground: "new 0 0 512 512" }}>
      <g>
        <g>
          <path d="M317.123,371.819c-10.584-2.313-18.268-11.871-18.268-22.728c0-8.336,4.514-16.087,11.778-20.231l8.809-5.021v-52.175
            c0-40.568-32.885-73.591-73.399-73.834V81.455h11.636V0h-58.182v81.455h11.636V197.83c-40.513,0.243-73.399,33.266-73.399,73.834
            v52.175l8.809,5.021c7.266,4.144,11.778,11.895,11.778,20.231c0,10.857-7.683,20.415-18.269,22.727l-13.731,2.998v53.752
            c0,46.004,37.428,83.433,83.433,83.433h37.666c46.004,0,83.433-37.428,83.433-83.433v-53.751L317.123,371.819z M166.405,450.327
            h8.254c16.543,0,30.473,11.394,34.362,26.745C190.398,476.793,174.292,465.976,166.405,450.327z M295.946,428.567
            c0,26.755-21.768,48.524-48.524,48.524h-1.379v-69.818h-34.909v18.359c-10.643-6.479-23.131-10.213-36.475-10.213h-13.427v-14.393
            c19.157-9.735,32-29.751,32-51.934c0-17.223-7.689-33.439-20.587-44.387v-33.042c0-21.319,17.225-38.683,38.49-38.925v58.171
            h34.909v-58.17c21.264,0.242,38.49,17.606,38.49,38.925v33.041c-12.898,10.946-20.587,27.164-20.587,44.387
            c0,22.184,12.843,42.199,32,51.934V428.567z"/>
        </g>
      </g>
      <g>
        <g>
          <rect x="211.132" y="331.636" width="34.909" height="34.909"/>
        </g>
      </g>
      <g>
        <g>
          <rect x="350.768" width="34.909" height="512"/>
        </g>
      </g>
    </svg>
  )
}